export const getFileTypeIcon = (type) => {
  if (!type) {
    return '$fileUnknown';
  }
  if (['application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'].includes(type)) {
    return '$fileWord';
  }
  if (['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'].includes(type)) {
    return '$fileExcel';
  }
  if (type.match('video.*')) {
    return '$fileVideo';
  }
  if (['application/vnd.ms-powerpoint', 'application/vnd.openxmlformats-officedocument.presentationml.presentation'].includes(type)) {
    return '$filePowerpoint';
  }
  if (type === 'application/pdf') {
    return '$filePdf';
  }
  return '$fileUnknown';
};

export const typeIsImage = (type) => type && type.match('image/*');
